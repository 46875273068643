@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
    font-family: "Montserrat";
    src: url("../fonts/Montserrat-VariableFont_wght.ttf");
}
